// plugins/jsencrypt.ts
import { JSEncrypt } from 'jsencrypt';

export default defineNuxtPlugin(() => {
  // nuxtApp.provide('encryptor', new JSEncrypt());
  console.log('jsencrypt defineNuxtPlugin')
  return {
    provide: {
      encryptor: new JSEncrypt()
    }
  }
});