import { default as apiServicesA4z6k4iynDMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue?macro=true";
import { default as _91slug_93PPSF7ZItlfMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue?macro=true";
import { default as indexLPX8mxhCtrMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue?macro=true";
import { default as indexO8z2a8GTwXMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue?macro=true";
import { default as MagicTigerImageAPIDocumentzM6TSZb5M2Meta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue?macro=true";
import { default as orderJD5Ta4Zm4uMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue?macro=true";
import { default as _91funcType_93hmsGf6PrHDMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue?macro=true";
import { default as enhanceBJkKuJ9XBzMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue?macro=true";
import { default as privacypolicydT5qLtcp6wMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue?macro=true";
import { default as termsofuseFl3tRX6FuXMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue?macro=true";
import { default as _91tag_93uvoh4uDrBmMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue?macro=true";
import { default as indexCy5cmwZCx8Meta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue?macro=true";
import { default as searchiezCSAW32iMeta } from "/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue?macro=true";
export default [
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___en",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___ja",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/ja/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___zh",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/zh/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___zh_Hant",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/zh_Hant/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___es",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/es/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___de",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/de/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___fr",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/fr/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___ru",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/ru/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: apiServicesA4z6k4iynDMeta?.name ?? "apiServices___pt",
    path: apiServicesA4z6k4iynDMeta?.path ?? "/pt/apiServices",
    meta: apiServicesA4z6k4iynDMeta || {},
    alias: apiServicesA4z6k4iynDMeta?.alias || [],
    redirect: apiServicesA4z6k4iynDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/apiServices.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___en",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___ja",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/ja/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___zh",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/zh/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___zh_Hant",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/zh_Hant/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___es",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/es/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___de",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___fr",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___ru",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/ru/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PPSF7ZItlfMeta?.name ?? "blog-slug___pt",
    path: _91slug_93PPSF7ZItlfMeta?.path ?? "/pt/blog/:slug()",
    meta: _91slug_93PPSF7ZItlfMeta || {},
    alias: _91slug_93PPSF7ZItlfMeta?.alias || [],
    redirect: _91slug_93PPSF7ZItlfMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___en",
    path: indexLPX8mxhCtrMeta?.path ?? "/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___ja",
    path: indexLPX8mxhCtrMeta?.path ?? "/ja/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___zh",
    path: indexLPX8mxhCtrMeta?.path ?? "/zh/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___zh_Hant",
    path: indexLPX8mxhCtrMeta?.path ?? "/zh_Hant/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___es",
    path: indexLPX8mxhCtrMeta?.path ?? "/es/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___de",
    path: indexLPX8mxhCtrMeta?.path ?? "/de/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___fr",
    path: indexLPX8mxhCtrMeta?.path ?? "/fr/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___ru",
    path: indexLPX8mxhCtrMeta?.path ?? "/ru/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPX8mxhCtrMeta?.name ?? "blog___pt",
    path: indexLPX8mxhCtrMeta?.path ?? "/pt/blog",
    meta: indexLPX8mxhCtrMeta || {},
    alias: indexLPX8mxhCtrMeta?.alias || [],
    redirect: indexLPX8mxhCtrMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___en",
    path: indexO8z2a8GTwXMeta?.path ?? "/",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___ja",
    path: indexO8z2a8GTwXMeta?.path ?? "/ja",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___zh",
    path: indexO8z2a8GTwXMeta?.path ?? "/zh",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___zh_Hant",
    path: indexO8z2a8GTwXMeta?.path ?? "/zh_Hant",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___es",
    path: indexO8z2a8GTwXMeta?.path ?? "/es",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___de",
    path: indexO8z2a8GTwXMeta?.path ?? "/de",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___fr",
    path: indexO8z2a8GTwXMeta?.path ?? "/fr",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___ru",
    path: indexO8z2a8GTwXMeta?.path ?? "/ru",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexO8z2a8GTwXMeta?.name ?? "index___pt",
    path: indexO8z2a8GTwXMeta?.path ?? "/pt",
    meta: indexO8z2a8GTwXMeta || {},
    alias: indexO8z2a8GTwXMeta?.alias || [],
    redirect: indexO8z2a8GTwXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___en",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___ja",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/ja/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___zh",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/zh/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___zh_Hant",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/zh_Hant/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___es",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/es/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___de",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/de/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___fr",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/fr/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___ru",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/ru/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.name ?? "MagicTigerImageAPIDocument___pt",
    path: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.path ?? "/pt/MagicTigerImageAPIDocument",
    meta: MagicTigerImageAPIDocumentzM6TSZb5M2Meta || {},
    alias: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.alias || [],
    redirect: MagicTigerImageAPIDocumentzM6TSZb5M2Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/MagicTigerImageAPIDocument.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___en",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___ja",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/ja/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___zh",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/zh/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___zh_Hant",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/zh_Hant/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___es",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/es/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___de",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/de/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___fr",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/fr/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___ru",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/ru/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: orderJD5Ta4Zm4uMeta?.name ?? "order___pt",
    path: orderJD5Ta4Zm4uMeta?.path ?? "/pt/order",
    meta: orderJD5Ta4Zm4uMeta || {},
    alias: orderJD5Ta4Zm4uMeta?.alias || [],
    redirect: orderJD5Ta4Zm4uMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/order.vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___en",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___ja",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/ja/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___zh",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/zh/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___zh_Hant",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/zh_Hant/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___es",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/es/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___de",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/de/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___fr",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/fr/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___ru",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/ru/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: _91funcType_93hmsGf6PrHDMeta?.name ?? "picma_web-funcType___pt",
    path: _91funcType_93hmsGf6PrHDMeta?.path ?? "/pt/picma_web/:funcType()",
    meta: _91funcType_93hmsGf6PrHDMeta || {},
    alias: _91funcType_93hmsGf6PrHDMeta?.alias || [],
    redirect: _91funcType_93hmsGf6PrHDMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/[funcType].vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___en",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___ja",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/ja/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___zh",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/zh/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___zh_Hant",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/zh_Hant/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___es",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/es/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___de",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/de/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___fr",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/fr/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___ru",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/ru/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: enhanceBJkKuJ9XBzMeta?.name ?? "picma_web-enhance___pt",
    path: enhanceBJkKuJ9XBzMeta?.path ?? "/pt/picma_web/enhance",
    meta: enhanceBJkKuJ9XBzMeta || {},
    alias: enhanceBJkKuJ9XBzMeta?.alias || [],
    redirect: enhanceBJkKuJ9XBzMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/enhance.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___en",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___ja",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/ja/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___zh",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/zh/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___zh_Hant",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/zh_Hant/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___es",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/es/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___de",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/de/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___fr",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/fr/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___ru",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/ru/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: privacypolicydT5qLtcp6wMeta?.name ?? "picma_web-privacypolicy___pt",
    path: privacypolicydT5qLtcp6wMeta?.path ?? "/pt/picma_web/privacypolicy",
    meta: privacypolicydT5qLtcp6wMeta || {},
    alias: privacypolicydT5qLtcp6wMeta?.alias || [],
    redirect: privacypolicydT5qLtcp6wMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___en",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___ja",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/ja/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___zh",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/zh/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___zh_Hant",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/zh_Hant/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___es",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/es/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___de",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/de/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___fr",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/fr/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___ru",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/ru/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofuseFl3tRX6FuXMeta?.name ?? "picma_web-termsofuse___pt",
    path: termsofuseFl3tRX6FuXMeta?.path ?? "/pt/picma_web/termsofuse",
    meta: termsofuseFl3tRX6FuXMeta || {},
    alias: termsofuseFl3tRX6FuXMeta?.alias || [],
    redirect: termsofuseFl3tRX6FuXMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/picma_web/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___en",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___ja",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/ja/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___zh",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/zh/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___zh_Hant",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/zh_Hant/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___es",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/es/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___de",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/de/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___fr",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/fr/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___ru",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/ru/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: _91tag_93uvoh4uDrBmMeta?.name ?? "support-tag___pt",
    path: _91tag_93uvoh4uDrBmMeta?.path ?? "/pt/support/:tag()",
    meta: _91tag_93uvoh4uDrBmMeta || {},
    alias: _91tag_93uvoh4uDrBmMeta?.alias || [],
    redirect: _91tag_93uvoh4uDrBmMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/[tag].vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___en",
    path: indexCy5cmwZCx8Meta?.path ?? "/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___ja",
    path: indexCy5cmwZCx8Meta?.path ?? "/ja/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___zh",
    path: indexCy5cmwZCx8Meta?.path ?? "/zh/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___zh_Hant",
    path: indexCy5cmwZCx8Meta?.path ?? "/zh_Hant/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___es",
    path: indexCy5cmwZCx8Meta?.path ?? "/es/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___de",
    path: indexCy5cmwZCx8Meta?.path ?? "/de/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___fr",
    path: indexCy5cmwZCx8Meta?.path ?? "/fr/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___ru",
    path: indexCy5cmwZCx8Meta?.path ?? "/ru/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexCy5cmwZCx8Meta?.name ?? "support___pt",
    path: indexCy5cmwZCx8Meta?.path ?? "/pt/support",
    meta: indexCy5cmwZCx8Meta || {},
    alias: indexCy5cmwZCx8Meta?.alias || [],
    redirect: indexCy5cmwZCx8Meta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___en",
    path: searchiezCSAW32iMeta?.path ?? "/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___ja",
    path: searchiezCSAW32iMeta?.path ?? "/ja/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___zh",
    path: searchiezCSAW32iMeta?.path ?? "/zh/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___zh_Hant",
    path: searchiezCSAW32iMeta?.path ?? "/zh_Hant/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___es",
    path: searchiezCSAW32iMeta?.path ?? "/es/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___de",
    path: searchiezCSAW32iMeta?.path ?? "/de/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___fr",
    path: searchiezCSAW32iMeta?.path ?? "/fr/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___ru",
    path: searchiezCSAW32iMeta?.path ?? "/ru/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  },
  {
    name: searchiezCSAW32iMeta?.name ?? "support-search___pt",
    path: searchiezCSAW32iMeta?.path ?? "/pt/support/search",
    meta: searchiezCSAW32iMeta || {},
    alias: searchiezCSAW32iMeta?.alias || [],
    redirect: searchiezCSAW32iMeta?.redirect || undefined,
    component: () => import("/var/jenkins_home/workspace/picmastudio-web-2/pages/support/search.vue").then(m => m.default || m)
  }
]