import { initializeApp } from "firebase/app";
export default defineNuxtPlugin(() => {
  // nuxtApp.provide('encryptor', new JSEncrypt());
  const {
    public: { MODE },
  } = useRuntimeConfig();
  console.log("firebase", MODE);

  let firebaseConfig = {
    // apiKey: "AIzaSyAywRUWI2e4AK9IzfGxhPNjaA3989cEcMc",
    // authDomain: "picma---photo-enhancer-remini.firebaseapp.com",
    apiKey: "AIzaSyDlD8kfBNaAcecB_gd_lwQr4hgHxFpiliE",
    authDomain: "picma-83436.firebaseapp.com",
    projectId: "picma-83436",
    storageBucket: "picma-83436.appspot.com",
    messagingSenderId: "622054936787",
    appId: "1:622054936787:web:83ccd795452095ae41c426",
    measurementId: "G-K7ZQ3BSN9E",
    //下面是H5Activity
    // appId: "1:622054936787:web:3c013014b5b76ac241c426",
    // measurementId: "G-F238LXNNM3",
  };
  if (MODE !== "pro") {
    firebaseConfig = {
      // apiKey: "AIzaSyAywRUWI2e4AK9IzfGxhPNjaA3989cEcMc",
      // authDomain: "picma---photo-enhancer-remini.firebaseapp.com",
      apiKey: "AIzaSyB8tBZ62hra1guWxvA3P46nhrge9iOx7R8",
      authDomain: "picma---photo-enhancer-remini.firebaseapp.com",
      projectId: "picma---photo-enhancer-remini",
      storageBucket: "picma---photo-enhancer-remini.appspot.com",
      messagingSenderId: "268955111062",
      appId: "1:268955111062:web:0d2b09ad999b44089a7cb7",
      measurementId: "G-RW2MMT1BX5",
    };
  }
  const app = initializeApp(firebaseConfig);
  return {
    provide: {
      firebaseApp: app,
    },
  };
});
